import "./bootstrap";
import { createApp } from "vue";
import SubscriptionEvents from "./components/admin/SubscriptionEvents";
import SeatPlan from "./components/admin/SeatPlan.vue";
import SeatPlanBook from "./components/admin/SeatPlanBook.vue";
import SeatPlanSite from "./components/site/SeatPlan.vue";
import NoSeatPlan from "./components/site/NoSeatPlan.vue";
import SeatPlanCanvas from "./components/site/SeatPlanCanvas.vue";
import OrderSummary from "./components/site/OrderSummary.vue";
import Subscription from "./components/site/Subscription.vue";
import AdminSeatMapCanvas from "./components/admin/AdminSeatMapCanvas.vue";
import SeatMap from "./components/SeatMap.vue";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

// Admin components
const adminApp = createApp({});
adminApp.component("subscription-events", SubscriptionEvents);
adminApp.component("seat-plan", SeatPlan);
adminApp.component("seat-plan-book", SeatPlanBook);
adminApp.component("admin-seat-map-canvas", AdminSeatMapCanvas);
adminApp.mount("#app");

// Site components
const siteApp = createApp({});
siteApp.component("no-seat-plan", NoSeatPlan);
siteApp.component("seat-plan-canvas", SeatPlanCanvas);
siteApp.component("order-summary", OrderSummary);
// siteApp.component('seat-map', SeatMap);
siteApp.component("seat-plan", SeatPlanSite);
siteApp.component("subscription", Subscription);
siteApp.mount("#site-app");

gsap.registerPlugin(ScrollTrigger);
gsap.utils.toArray(".animate").forEach((element) => {
    gsap.from(element, {
        scrollTrigger: {
            trigger: element,
            start: "top 80%",
        },
        opacity: 0,
        y: 100,
        duration: 1,
    });
});
